@import "./tailwind.css";

html,
body,
#root {
  @apply bg-QtBlue overscroll-none;
}

.QtContainer {
  @apply flex justify-center items-center h-screen w-full;

  #QtWrapper {
    @apply relative w-60 h-60  flex justify-center items-center;

    #Qt {
      @apply absolute;
    }
  }
}
